
import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import InputBlock from '@/components/form/InputMain.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';
import RadioBtn from '@/components/form/RadioBtn.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import TimePicker from '@/components/form/TimePickerDefault.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import RemoveModal from '@/components/modules/removeModal.vue';

import ContentSliderEntity from '@/admin/store/content/slider/entity';
import { ContentSliderModelInterface } from '@/admin/store/content/slider/entityModel';
import { imageToFile } from '@/lib/utils/Utils';

import moment from 'moment';

@Component({
    components: {
        Loading,
        TitleReturn,
        InputBlock,
        ImageBlock,
        CheckboxCustom,
        RadioBtn,
        DatePicker,
        TimePicker,
        BtnDefault,
        BtnSubmit,
        RemoveModal,
    },
})
export default class SliderCard extends Vue {
    get isLoading(): boolean {
        return ContentSliderEntity.isLoading;
    }

    get model(): ContentSliderModelInterface {
        return ContentSliderEntity.model;
    }

    get isEditPage(): boolean {
        return this.$route.name === 'slider_content_card';
    }

    updateTitle(value: string): void {
        ContentSliderEntity.updateTitle(value);
    }

    async handleImage(file: File): void {
        const toFile = await imageToFile(file);

        ContentSliderEntity.updateImageFile(toFile);
    }

    removePreview(): void {
        ContentSliderEntity.removeImagePreview();
    }

    handleDate(params: { day: string; month: string; year: string }): void {
        const dateFormatted = moment(`${params.day}/${params.month}/${params.year}`, 'DD/MM/YYYY').format('DD/MM/YY');
        ContentSliderEntity.updateDate(dateFormatted);
    }

    updateTime(params: { type: string; value: number | string }): void {
        ContentSliderEntity.updateTime(params);
    }

    updateTimerEndText(value: string): void {
        ContentSliderEntity.updateTimerEndText(value);
    }

    updateShowOnMain(params: { bool: boolean; data_value: unknown; id: number; name: unknown }): void {
        ContentSliderEntity.updateShowOnMain(params.bool);
    }

    updateIsTimer(params: { bool: boolean; data_value: unknown; id: number; name: unknown }): void {
        ContentSliderEntity.updateIsTimer(params.bool);
    }

    updateSort(value: string): void {
        ContentSliderEntity.updateSort(value);
    }

    updateLink(value: string): void {
        ContentSliderEntity.updateLink(value);
    }

    send(): void {
        this.isEditPage ? ContentSliderEntity.sendEdit() : ContentSliderEntity.sendCreate();
    }

    returnBefore(): void {
        this.$router.push({ name: 'content_main_page' });
    }

    created(): void {
        if (this.isEditPage) {
            ContentSliderEntity.initCardById(+this.$route.params.cardId);

            return;
        }

        ContentSliderEntity.SET_IS_LOADING(false);
    }

    beforeDestroy(): void {
        ContentSliderEntity.reset();
    }
}
