import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment/moment.js';

@Component
export default class DatePickerMixin extends Vue {
    @Prop({
        required: true,
        default: () => ({
            key: 'date',
            type: 'date-picker',
            title: 'С даты',
            placeholder: '',
            value: '21/02/20',
            action: 'user/auth/updateLogin',
            required: true,
            disabled: false,
            error: {
                class: '',
                message: '',
            },
            validation: {
                empty: '',
                incorrect: '',
            },
        }),
    })
    readonly data!: any;

    visible = false;
    dayOfWeeks = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
    month: any = {};
    dateFormat = 'DD/MM/YYYY';
    currentValue: {
        month: string | null;
        day: string | null;
    } = {
        month: null,
        day: null,
    };

    documentClick(e: Event) {
        const el = this.$refs.datePickerDefault as HTMLElement;
        const target = e.target as Node;

        if (this.visible === true) {
            if (el && el !== target && !el.contains(target)) {
                this.visible = false;
            }
        }
    }

    init() {
        if (this.data.value.indexOf('/') === -1) {
            return;
        }

        const dateCurrent = this.data.value.split('/');
        const momentDate = this.data.value;

        const monthName = moment(momentDate, this.dateFormat).format('MMMM');
        const year = moment(momentDate, this.dateFormat).format('YYYY');
        const startOfMonth = moment(momentDate, this.dateFormat).startOf('month').date();
        const endOfMonth = moment(momentDate, this.dateFormat).endOf('month').date();

        const weeks: { [index: number]: { [index: number]: number } } = {};
        let weekIndex = 0;
        let weekValue = null;
        let weekOfYear, dayOfWeek;
        for (let index = startOfMonth; index <= endOfMonth; index++) {
            weekOfYear = moment(`${index}/${dateCurrent[1]}/${dateCurrent[2]}`, this.dateFormat).isoWeek();
            dayOfWeek = moment(`${index}/${dateCurrent[1]}/${dateCurrent[2]}`, this.dateFormat).isoWeekday();

            if (weekOfYear !== weekValue) {
                weekValue = weekOfYear;
                weekIndex++;
            }

            if (weeks[weekIndex]) {
                weeks[weekIndex][dayOfWeek] = index;
            } else {
                weeks[weekIndex] = {};
                weeks[weekIndex][dayOfWeek] = index;
            }
        }

        this.month = {
            month: moment(momentDate, this.dateFormat).format('M'),
            year: year,
            name: `${monthName} ${year}`,
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth,
            weeks: weeks,
            currentDay: dateCurrent[0],
        };
    }

    toggleVisible() {
        if (this.data.disabled) {
            return;
        }
        if (this.data.value.indexOf('/') === -1) {
            this.data.value = moment().format(this.dateFormat);
            this.init();
        }
        if (isNaN(this.month.startOfMonth)) {
            this.init();
        }
        this.visible = !this.visible;
    }

    selectValue(dayParam: number) {
        if (!dayParam) {
            return;
        }
        const day = String(dayParam);

        let month = this.month.month;
        if (parseInt(month) < 10) {
            month = `0${month}`;
        }

        const newValue = {
            day: day,
            month: month,
            year: this.month.year,
        };
        this.visible = false;
        this.$emit('click', newValue);

        const momentDate = `${month}/${day}/${this.month.year}`;
        this.currentValue.day = day;
        this.currentValue.month = moment(momentDate, this.dateFormat).format('MMM');
        this.currentValue.month = this.currentValue.month.replace('.', '');
    }

    next() {
        if (parseInt(this.month.month) === 12) {
            this.month.year = parseInt(this.month.year) + 1;
            this.getMonth(1);

            return;
        }

        this.getMonth(parseInt(this.month.month) + 1);
    }

    prev() {
        if (parseInt(this.month.month) === 1) {
            this.month.year = parseInt(this.month.year) - 1;
            this.getMonth(12);

            return;
        }

        this.getMonth(parseInt(this.month.month) - 1);
    }

    getMonth(number: number) {
        let month = String(number);
        if (parseInt(String(month)) < 10) {
            month = `0${month}`;
        }

        this.month.month = number;
        const momentDate = `01/${month}/${this.month.year}`;
        const monthName = moment(momentDate, this.dateFormat).format('MMMM');
        const startOfMonth = moment(momentDate, this.dateFormat).startOf('month').date();
        const endOfMonth = moment(momentDate, this.dateFormat).endOf('month').date();

        const weeks: { [index: number]: { [index: number]: number } } = {};
        let weekIndex = 0;
        let weekValue = null;
        let weekOfYear, dayOfWeek;
        for (let index = startOfMonth; index <= endOfMonth; index++) {
            weekOfYear = moment(`${index}/${this.month.month}/${this.month.year}`, this.dateFormat).isoWeek();
            dayOfWeek = moment(`${index}/${this.month.month}/${this.month.year}`, this.dateFormat).isoWeekday();

            if (weekOfYear !== weekValue) {
                weekValue = weekOfYear;
                weekIndex++;
            }

            if (weeks[weekIndex]) {
                weeks[weekIndex][dayOfWeek] = index;
            } else {
                weeks[weekIndex] = {};
                weeks[weekIndex][dayOfWeek] = index;
            }
        }

        this.month.name = `${monthName} ${this.month.year}`;
        this.month.weeks = weeks;
        this.month.startOfMonth = startOfMonth;
        this.month.endOfMonth = endOfMonth;
    }

    mounted() {
        this.init();
    }
    created() {
        document.body.addEventListener('click', this.documentClick);
    }
    updated() {
        document.body.addEventListener('click', this.documentClick);
    }
    destroyed() {
        document.body.addEventListener('click', this.documentClick);
    }
}
