import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest, makeDeleteRequest, FormDataObject } from '@/api/helpers';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

interface CreatedByUserInterface {
    id: number;
    fullName: string;
}

export interface ContentSliderCardInterface {
    [key: string]: number | string | boolean | CreatedByUserInterface;
    id: number;
    title: string;
    image: string;
    sort: number;
    link: string;
    timerEnd: string;
    timerEndText: string;
    showOnMain: boolean;
    createdByUser: CreatedByUserInterface;
}

export interface ContentSliderCardFormInterface {
    id: string;
    title: string;
    image: string;
    sort: string;
    link: string;
    timerEnd: string;
    timerEndText: string;
    showOnMain: string;
}

export interface sliderCardRequestDataInterface {
    [key: string]: number | string | boolean | File | undefined;
    title: string;
    image: File | string;
    sort: number;
    link: string;
    showOnMain: number;
    timerEnd?: string;
    timerEndText?: string;
}

export const getSliderContentTable = async (
    pageCurrent: number,
    sort: string,
    filter: string,
    items: string,
): Promise<TableApiInterface> => {
    const result = await makeGetRequest(
        `${API.sliderContent.list}?page=${pageCurrent}&items=${items}&${sort}${filter}`,
    );

    return result.data.result.table;
};

export const getSliderContentCard = async (id: number): Promise<ContentSliderCardInterface> => {
    const result = await makeGetRequest(`${API.sliderContent.card}${id}/`);

    return result.data.result.item;
};

export const editSliderContentCard = async (
    id: number,
    data: sliderCardRequestDataInterface,
): Promise<ContentSliderCardInterface> => {
    const result = await makeFormRequest(`${API.sliderContent.card}${id}/`, data as FormDataObject);

    return result.data.result.item;
};

export const createSliderContentCard = async (
    data: sliderCardRequestDataInterface,
): Promise<ContentSliderCardFormInterface> => {
    const result = await makeFormRequest(`${API.sliderContent.create}`, data as FormDataObject);

    return result.data.result.item;
};

export const deleteSliderContentCard = async (id: number): Promise<string> => {
    const result = await makeDeleteRequest(`${API.sliderContent.card}${id}`);

    return result.data.result.status;
};
