import { Checkbox } from '@/lib/formFactory/checkbox.interface';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import { ImageInput } from '@/lib/formFactory/imageInput.interface';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import { InputDatePicker } from '@/lib/formFactory/inputDatePicker.interface';
import InputDatePickerEntity from '@/lib/formFactory/inputDatePickerEntity';
import InputEntity from '@/lib/formFactory/inputEntity';
import { Input } from '@/lib/formFactory/input.interface';
import { InputTimePicker } from '@/lib/formFactory/inputTimePicker.interface';
import InputTimePickerEntity from '@/lib/formFactory/inputTimePickerEntity';

export interface ContentSliderModelInterface {
    [key: string]: Input | ImageInput | InputDatePicker | InputTimePicker | Checkbox;
    title: Input;
    image: ImageInput;
    sort: Input;
    link: Input;
    dateEnd: InputDatePicker;
    timeEnd: InputTimePicker;
    timerEndText: Input;
    showOnMain: Checkbox;
    isTimer: Checkbox;
}

export default class EntityModel {
    model: ContentSliderModelInterface;

    constructor() {
        const titleEntity = new InputEntity('title');
        titleEntity.setTitle('Заголовок');
        titleEntity.setRequired(true);

        const imageEntity = new ImageInputEntity('image');
        imageEntity.setMinWidth(1280);

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');
        sortEntity.setRequired(true);

        const linkEntity = new InputEntity('link');
        linkEntity.setTitle('Ссылка');
        linkEntity.setPlaceholder('/auction-house/1');
        linkEntity.setRequired(true);

        const dateEndEntity = new InputDatePickerEntity('dateStart');
        const timeEndEntity = new InputTimePickerEntity('time');

        const showOnMainEntity = new CheckboxEntity('showOnMain');
        showOnMainEntity.setList({
            0: {
                id: 1,
                name: 'Показывать на главной',
                checked: false,
            },
        });

        const isTimerEntity = new CheckboxEntity('isTimer');
        isTimerEntity.setList({
            0: {
                id: 1,
                name: 'Таймер',
                checked: false,
            },
        });

        const timerEndTextEntity = new InputEntity('timerEndText');
        timerEndTextEntity.setTitle('Текст слайда');
        timerEndTextEntity.setRequired(true);

        this.model = {
            title: titleEntity.entity[titleEntity.entityName],
            image: imageEntity.entity[imageEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            link: linkEntity.entity[linkEntity.entityName],
            dateEnd: dateEndEntity.entity[dateEndEntity.entityName],
            timeEnd: timeEndEntity.entity[timeEndEntity.entityName],
            timerEndText: timerEndTextEntity.entity[timerEndTextEntity.entityName],
            showOnMain: showOnMainEntity.entity[showOnMainEntity.entityName],
            isTimer: isTimerEntity.entity[isTimerEntity.entityName],
        };
    }
}
