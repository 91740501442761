import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import BaseEntityModule from '@/admin/store/entity';
import store from '@/admin/store';
import { createSliderContentCard, editSliderContentCard, getSliderContentCard } from '@/admin/api/sliderContent';
import type { sliderCardRequestDataInterface, ContentSliderCardInterface } from '@/admin/api/sliderContent';
import { ContentSliderModelInterface } from './entityModel';
import EntityModel from './entityModel';
import responseHandler from '../../modules/responseHandler';
import {
    formatDateToDefault,
    formatDefaultDateTo,
    formatToRelativeUrl,
    getApiErrorMessage,
    imageFileToBase64,
} from '@/lib/utils/Utils';
import { InputDatePicker } from '@/lib/formFactory/inputDatePicker.interface';
import { InputTimePicker } from '@/lib/formFactory/inputTimePicker.interface';
import SiteModule from '@/admin/store/site';

const MODULE_NAME = 'sliderContentCard';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SliderContentCard extends BaseEntityModule {
    model: ContentSliderModelInterface;

    constructor(module: SliderContentCard) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_TITLE(value: string) {
        this.model.title.value = value;
    }

    @Mutation
    SET_IMAGE_PREVIEW(preview: string) {
        this.model.image.preview = preview;
    }

    @Mutation
    SET_IMAGE_FILE(file: File | null) {
        this.model.image.file = file;
    }

    @Mutation
    SET_SHOW_ON_MAIN(bool: boolean) {
        this.model.showOnMain.list[0].checked = bool;
    }

    @Mutation
    SET_IS_TIMER(bool: boolean) {
        this.model.isTimer.list[0].checked = bool;
    }

    @Mutation
    SET_DATE_END(value: string) {
        this.model.dateEnd.value = value;
    }

    @Mutation
    SET_TIME_END(value: string) {
        const splitted = value.split(':');

        this.model.timeEnd.value = value;
        this.model.timeEnd.hour = splitted[0];
        this.model.timeEnd.minute = splitted[1];
    }

    @Mutation
    SET_TIME_HOUR(hour: string | number) {
        if (typeof hour === 'number') {
            this.model.timeEnd.hour = hour.toString();
        }

        this.model.timeEnd.value = `${hour}:00`;
    }

    @Mutation
    SET_TIME_MINUTE(minute: string | number) {
        if (typeof minute === 'number') {
            minute = minute.toString();
        }

        this.model.timeEnd.minute = minute;
        this.model.timeEnd.value = `${this.model.timeEnd.hour}:${minute}`;
    }

    @Mutation
    SET_TIMER_END_TEXT(value: string) {
        this.model.timerEndText.value = value;
    }

    @Mutation
    SET_LINK(value: string) {
        this.model.link.value = value;
    }

    @Action({ rawError: true })
    async initCardById(id: number): Promise<void> {
        this.getInfo(id);
    }

    @Action({ rawError: true })
    async getInfo(id: number): Promise<void> {
        this.SET_ID(String(id));
        try {
            this.SET_IS_LOADING(true);
            const result = await getSliderContentCard(id);

            await this.setInfo(result);
            this.SET_IS_LOADING(false);
        } catch (error) {
            this.SET_IS_LOADING(false);
            responseHandler.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    setInfo(data: ContentSliderCardInterface) {
        const timerRelatedFieldNames = ['timerEnd', 'timerEndText'];
        Object.keys(data).forEach((key: string) => {
            if (key === 'showOnMain') {
                this.SET_SHOW_ON_MAIN(data[key]);

                return;
            }

            if (timerRelatedFieldNames.includes(key) && (data[key] as string)?.length) {
                this.SET_IS_TIMER(true);
            }

            if (key === 'timerEnd' && data[key]) {
                this.SET_DATE_END(formatDefaultDateTo(data[key] as string, 'DD/MM/YYYY'));
                this.SET_TIME_END((data[key] as string).split('T')[1]);

                return;
            }

            if (this.model[key] == null || !('value' in this.model[key])) {
                return;
            }

            if (key === 'link') {
                this.SET_LINK(formatToRelativeUrl(data[key]));

                return;
            }

            this.SET_MODEL_FIELD_VALUE({ field: key, value: data[key] as string });
        });
    }

    @Action({ rawError: true })
    updateTitle(value: string) {
        this.RESET_ERROR_BY_FIELD('title');
        this.SET_TITLE(value);
    }

    @Action({ rawError: true })
    async updateImageFile(file: File) {
        this.SET_IMAGE_FILE(file);

        const preview = await imageFileToBase64(file);
        this.SET_IMAGE_PREVIEW(preview as string);
    }

    @Action({ rawError: true })
    removeImagePreview() {
        this.SET_IMAGE_FILE(null);
        this.SET_IMAGE_PREVIEW('');
    }

    @Action({ rawError: true })
    updateDate(date: string) {
        this.RESET_ERROR_BY_FIELD('dateEnd');
        this.SET_DATE_END(date);

        if (!this.model.timeEnd.value) {
            this.SET_TIME_HOUR('18');
            this.SET_TIME_MINUTE('00');
        }
    }

    @Action({ rawError: true })
    updateTime(params: { type: string; value: number | string }) {
        if (params.type === 'hour') {
            this.SET_TIME_HOUR(params.value);

            return;
        }

        this.SET_TIME_MINUTE(params.value);
    }

    @Action({ rawError: true })
    updateTimerEndText(value: string): void {
        this.SET_TIMER_END_TEXT(value);
    }

    @Action({ rawError: true })
    updateShowOnMain(bool: boolean) {
        this.SET_SHOW_ON_MAIN(bool);
    }

    @Action({ rawError: true })
    updateIsTimer(bool: boolean): void {
        this.SET_IS_TIMER(bool);
    }

    @Action({ rawError: true })
    updateSort(value: string): void {
        this.RESET_ERROR_BY_FIELD('sort');
        this.SET_SORT_ORDER(value);
    }

    @Action({ rawError: true })
    updateLink(value: string): void {
        this.RESET_ERROR_BY_FIELD('link');
        this.SET_LINK(formatToRelativeUrl(value));
    }

    @Action({ rawError: true })
    async validate(): Promise<boolean> {
        const isTimerChecked = this.model.isTimer.list[0].checked;
        this.setValidateState({ index: 'title', field: this.model.title });
        this.setValidateState({ index: 'link', field: this.model.link });
        this.setValidateState({ index: 'sort', field: this.model.sort });

        if (isTimerChecked && !this.model.dateEnd.value.length) {
            this.SET_ERROR_BY_FIELD({ field: 'dateEnd', message: '' });
        }

        if (isTimerChecked && !this.model.timeEnd.value.length) {
            this.SET_ERROR_BY_FIELD({ field: 'timeEnd', message: '' });
        }

        if (!this.model.image.file && !this.model.image.value.length) {
            this.SET_ERROR_BY_FIELD({ field: 'image', message: '' });
        }

        const fieldsWithError = await this.getFieldsWithError(Object.keys(this.model));

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData(): sliderCardRequestDataInterface {
        const data: sliderCardRequestDataInterface = {} as sliderCardRequestDataInterface;
        data['title'] = this.model.title.value;
        data['sort'] = +this.model.sort.value;
        data['link'] = this.model.link.value;
        data['showOnMain'] = +this.model.showOnMain.list[0].checked;

        if (this.model.image.file) {
            data['image'] = this.model.image.file;
        }

        if (this.model.timerEndText.value) {
            data['timerEndText'] = this.model.timerEndText.value;
        }

        if (this.model.isTimer.list[0].checked) {
            const date = formatDateToDefault((this.model.dateEnd as InputDatePicker).value, 'DD/MM/YYYY');
            const timeEntity = this.model.timeEnd as InputTimePicker;
            const time = `${timeEntity.hour}:${timeEntity.minute}:00`;
            data['timerEnd'] = `${date.split('T')[0]}T${time}`;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit(): Promise<void> {
        const isError = await this.validate();

        if (isError === true) {
            responseHandler.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        try {
            SiteModule.SET_IS_BLOCK(true);
            const data = await this.prepareData();
            const result = await editSliderContentCard(+this.id, data);

            if (result.id) {
                await this.setInfo(result);
                responseHandler.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            }
            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            responseHandler.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async sendCreate() {
        const isError = await this.validate();

        if (isError) {
            responseHandler.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        try {
            SiteModule.SET_IS_BLOCK(true);
            const data = await this.prepareData();
            const result = await createSliderContentCard(data);

            if (result.id) {
                responseHandler.showNotify({ message: 'Слайд создан', type: 'ok' });
            }
            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            responseHandler.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        this.SET_SHOW_ON_MAIN(false);
        this.SET_IS_TIMER(false);

        Object.keys(this.model).forEach((key) => {
            if ('disabled' in this.model[key]) {
                this.SET_FIELD_DISABLED_BY_TYPE({ type: key, disabled: false });
            }

            if ('preview' in this.model[key]) {
                this.SET_IMAGE_PREVIEW('');
            }

            if ('file' in this.model[key]) {
                this.SET_IMAGE_FILE(null);
            }

            if ('value' in this.model[key]) {
                this.SET_MODEL_FIELD_VALUE({ field: key, value: '' });
            }
        });
    }
}
export default getModule(SliderContentCard);
